<template>
  <div class="container-receitas" id="topo">
        <!-- -->
      <section class="container-fluid">
         <div class="row" style="background-color:#E08000;">
          <div class="col-sm-4 fundo-laranja quadro" ><br>

  
          <h3 class="txt-white bold">Que ingredientes quer usar?</h3>
  
                  
           
                 
                             
<vue-bootstrap-typeahead 
  v-debounce:500.lock="search"
  v-model="queryIngredientes"
  :data="resultados"
  @hit="onSubmit"
  size="md"
  placeholder="Digite apenas as primeiras letras do ingrediente..."
  :maxMatches="limiteResultados"
  style="width:100%;"
  id="campo-busca"
  inputClass="campo-busca"
  ref="typeahead"
  
/>
 <img src="img/demo-lista.jpg" class="img-fluid d-block " />
                  
                   <!-- <div  v-if="showMessageAutocomplete" class="alert alert-warning" role="alert">
                      <button type="button" style="font-size:0.9em;" class="close" @click="fecharBoxIngrediente">X</button>
                      <small>
                  <strong>Atenção!</strong> É obrigatório selecionar seu ingrediente na lista de opções que aparece logo abaixo do campo de busca após digitar as primeiras letras.<br>
                 <strong>Não achou o seu ingrediente na lista? </strong> 
                 Você pode solicitar a inserção de seu ingrediente no campo abaixo.</small>
                 <input v-model="solicitacao" placeholder="Escreva aqui seu ingrediente" type="text" class="form-control" style="max-height:30px !important;margin-top:5px;" name="solicitar" />
                 <button @click="enviarSolicitacao" class="btn btn-light btn-block  bold" style="">ENVIAR</button>
                </div> -->
<br>


 <div style="background-color:#FFE7C9;min-height:255px;" class="px-2 py-2">
    <h4 class="bold " style="color:#495057;">Sua lista de busca</h4>
            <!-- -->
                  <button style="margin-right:3px;margin-bottom:5px;background:#764573 !important;border-color:#764573;" type="button" class="btn btn-sm btn-primary bold" v-for="(item,index) in ingredientes" :key="index" @click="removerIngrediente(index)">
                    {{item}} <span class="badge badge-light">X</span>
                  </button>
            <!-- -->

 </div><!-- div listagem ingredientes -->

<br>

<button @click="buscaPorIngredientes" v-scroll-to="'#resultados'" class="bold text-dark px-5 py-1" style="font-size:2.0em;border:none;background-color:#EEEEF0;">Buscar</button>
<!--<p class="text-white d-inline bold">Use o botão ao lado para fazer sua busca</p><img @click="buscaPorIngredientes" src="img/busca.png" v-scroll-to="'#resultados'" class="img-fluid d-inline-block px-2" style="cursor:pointer;"/> -->
                 <!-- <button v-scroll-to="'#resultados'" @click="buscarReceitas" class="btn btn-light btn-block btn-search-ingredients bold">BUSCAR</button><br>-->
                  <!-- <button @click="limparFiltos" class="btn btn-light btn-block btn-search-ingredients bold">LIMPAR FILTROS</button> -->
          </div>

          <div class="col-sm-4 fundo-roxo quadro"><br>

          <section id="quadro-filtros" v-if="showFiltros">
              <p class="txt-white bold text-center">Com as informações abaixo a gente pode encontrar com mais precisão o que você busca.
                 É simples: clique na seta de cada box e selecione as opções de filtro que quiser.</p>

          <!-- -->

          <div class="form-group ">
              <label class="txt-white">Categoria: <span @click="categoria = ''" style="cursor:pointer;background:#FFF;color:#000;width:15px;height:15px;" class="px-1 ">x</span></label>
              <select name="categoria" class="form-control" v-model="categoria">
                  <option value="">Selecione</option>
                  <option value="">Remover filtro</option>
                  <option value="Entradas">Entradas</option>
						<option value="Sopas, Cremes e Caldinhos">Sopas, Cremes e Caldinhos</option>
						<option value="Aperitivos, Comidinhas e Lanches">Aperitivos, Comidinhas e Lanches</option>
						<option value="Pratos Principais">Pratos Principais</option>
						<option value="Acompanhamentos">Acompanhamentos</option>
						<option value="Sobremesas">Sobremesas</option>
						<option value="Bolos, Doces e Pães">Bolos, Doces e Pães</option>
						<option value="Molhos e Caldos Clássicos">Molhos e Caldos Clássicos</option>
              </select>
          </div>

<div class="row">

             <div class="form-group col-sm-6">
              <label class="txt-white">Custo aproximado: <span @click="custo = ''" style="cursor:pointer;background:#FFF;color:#000;width:15px;height:15px;" class="px-1 ">x</span></label>
              <select name="custo" class="form-control" v-model="custo">
                  <option value="">Selecione</option>
                  <option value="">Remover filtro</option>
                  <option value="$">$</option>
                  <option value="$$">$$</option>
                  <option value="$$$">$$$</option>
                  <option value="$$$$">$$$$</option>
                  <option value="$$$$$">$$$$$</option>
              </select>
          </div>

           <div class="form-group col-sm-6">
              <label class="txt-white">Tempo de preparo: <span @click="tempo = 0" style="cursor:pointer;background:#FFF;color:#000;width:15px;height:15px;" class="px-1 ">x</span></label>
              <div class="input-group mb-3">
                <input class="form-control" min="0" name="tempo" type="number" v-model="tempo"/>
                <div class="input-group-append">
                      <span class="input-group-text">Min</span>
                    </div>
              </div>
          </div>     
 </div>

 <div class="row">
           <div class="form-group col-sm-6">
              <label class="txt-white">Dieta: <span @click="dieta = ''" style="cursor:pointer;background:#FFF;color:#000;width:15px;height:15px;" class="px-1 ">x</span></label>
              <select name="dieta" class="form-control" v-model="dieta">
                  <option value="">Selecione</option>
                  <option value="">Remover filtro</option>
                  <option v-for="(item,index) in dietas" :key="index"  :value="item.nome">{{item.nome}}</option>
              </select>
          </div>

          <div class="form-group col-sm-6">
              <label class="txt-white">Dificuldade: <span @click="dificuldade = ''" style="cursor:pointer;background:#FFF;color:#000;width:15px;height:15px;" class="px-1 ">x</span></label>
              <select name="dieta" class="form-control" v-model="dificuldade">
                  <option value="">Selecione</option>
                  <option value="">Remover filtro</option>
                  <option value="Fácil">Fácil</option>
                  <option value="Média">Média</option>
                  <option value="Difícil">Difícil</option>
              </select>
          </div>
 </div>
<br>
  <p class="txt-white bold text-center">Agora clique abaixo em aplicar filtros.</p>
   <button v-scroll-to="'#resultados'" @click="buscaPorIngredientes" class="btn btn-light btn-block btn-search-ingredients bold">APLICAR FILTROS</button>
   <button  @click="showFiltros = false" class="btn btn-light btn-block btn-search-ingredients bold">VOLTAR</button>
   
<!-- -->
</section>

<section id="quadro-icones" v-if="showFiltros == false">


<h3 class="text-white bold">Não encontrou seu ingrediente?</h3>
 <div class="input-group">
 <input v-model="solicitacao" placeholder="Por favor, digite o nome aqui e envie..." type="text" class="form-control" style="height:50px;max-width:80%;"    name="solicitar" />
 <div class="input-group-append">
     <img @click="enviarSolicitacao" src="img/enviar2.png" class="img-fluid" style="cursor:pointer;height:50px !important;width:auto;"/>
                     
                    </div>
 </div>
<!--<p class="text-white font-italic" style="font-size:.9em;">Priorizaremos esse ingrediente em novas receitas</p> -->
<br>
<hr style="border-color:#FFF;">
<br>


  <!--<div class="row">
  <div class="col-9 col-sm-"><h4 @click="buscarReceitas" v-scroll-to="'#resultados'" style="cursor:pointer;" class="txt-white bold">Clique aqui para buscar as receitas para você</h4></div>
  <div class="col-3"> <img @click="buscarReceitas" v-scroll-to="'#resultados'" src="img/busca.png" class="img-fluid d-inline" style="cursor:pointer;"/></div>
  </div> -->
<br>
  <div class="row">

    <div class="col-12"> <button @click="showFiltros = true"  class="bold text-dark px-5 py-1" style="font-size:2.0em;border:none;background-color:#EEEEF0;width:100%;">Afinar sua busca</button>
   <!-- <img @click="showFiltros = true" src="img/filtro.png" class="img-fluid  bt-filtros" style="cursor:pointer;"/> -->
    </div>
  
  
  </div>

  <br>
  <div class="row">
   
         <div class="col-12"> <button @click="limparFiltos"  class="bold text-dark px-2 py-1" style="font-size:1.9em;border:none;background-color:#EEEEF0;width:100%;">Limpar suas digitações</button></div>
 
  
  </div>
  <br>
  <hr style="border-color:#FFF;">
<br>
<br>
<h5 class="txt-white bold">Quer buscar uma receita pelo nome?</h5>
<AutocompleteReceita ref="nomeReceita" :receita.sync="buscaReceita" @buscarInformacao="buscarPorNome" url="autocomplete/receitas"></AutocompleteReceita>


  <p class="txt-white font-italic" style="font-size:.9em;" >Se ela aparecer selecione e depois clique no botão de busca.</p>
   
 
</section>


          </div>

          <div class="col-sm-4 quadro p-0 align-self-center" >
          
             <Anuncio :id="7" padrao="/img/background-find-recipe.svg" class="d-none d-sm-block " :mobile="false" />
              <Anuncio :id="7" padrao="/img/background-find-recipe.svg" class="d-sm-none " :mobile="true" />  
          </div>
             
         </div>
      </section>


<section class="container-fluid" style="background:#F9F7EA;">
<div class="row">
    <div class="col-sm-12"><br>
  <!-- <AutocompleteReceita ref="nomeReceita" :receita.sync="buscaReceita" @buscarInformacao="buscarReceitas" url="autocomplete/receitas"></AutocompleteReceita> -->
        <h4 id="resultados" style="color:#440D45;" class="bold">Receitas para você</h4><br><br>
        
        
        
        <div class="row">
          <CardReceita v-for="(item,index) in receitas" :key="index" :receita="item" ></CardReceita> 
        </div>
        
        <div v-if="showLoading" class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

  <p v-if="semResultados" class="text-center bold">Sem Resultados...</p>
 <div class="d-flex justify-content-center">
 
        <button v-if="semResultados == false" @click="paginar" style="width:300px;" class="btn btn-light btn-block btn-search-ingredients bold">CARREGAR MAIS RECEITAS</button>
         </div>
        
        <br><br>
    </div>
</div>
</section>


 <div class="modal" id="modal-hotsite" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header py-0 " style="background-color:#803B73;border-bottom:none !important;">
        <h5 class="modal-title"></h5>
        <button  type="button" class="close" data-dismiss="modal" aria-label="Close" style="color:#FFF;">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body py-0 px-0 modal-hotsite" style="background-color:#803B73;">
       <iframe src="https://tarimbanacozinha.com.br/TarimbadosContraFome/img/brasilsemfome.html"  frameborder="0" style="width:100%;height:450px;" />
      </div>
      
    </div>
  </div>
</div>

      <!-- -->
  </div>
</template>

<style>
@media all and (max-width:500px){
.modal-hotsite{height:250px !important;}
}
@media all and (max-width:420px){
.modal-hotsite{height:230px !important;}
}
.fundo-laranja{background:#F18800;padding-bottom:20px;}
.fundo-roxo{background:#764573;padding-bottom:20px;}
.quadro{min-height:300px;}
.txt-white{color:#FFF;}
.btn-search-ingredients {
    color: #400041;
}


</style>

<script>
import servidor from '@/servidor'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import AutocompleteReceita from '@/components/AutocompleteReceita'
import CardReceita from '@/components/CardReceita'
import Anuncio from '@/components/Anuncio'

export default {
  name: 'Receitas',
  metaInfo: {
    title: 'Encontre sua receita - Tarimba na cozinha',

    meta:[{name: 'description', 
    content: "Tarimba Na Cozinha. Que ingredientes quer usar? Encontre aqui todas as receitas que precisa com apenas aqueles ingredientes que tem disponivel no momento."}]
  },
  components: {VueBootstrapTypeahead,AutocompleteReceita,CardReceita,Anuncio},
  data(){
    return {
      showFiltros:false,
      showLoadingIngrediente:false,
      solicitacao:'',
      limiteResultados:50,
      resultados: [],
      selectedItem: null,
      showMessageAutocomplete:false,
      queryIngredientes: '',
      receitas:[],
      ingredientes:[],
      buscaReceita:'',
      dietas:[],
      dieta:'',
      categoria:'',
      dificuldade:'',
      custo:'',
      tempo: 0,
      showLoading:false,
      semResultados:false,
      buscaPorNome:false
    }
  },
  methods:{
     abrirModalHotsite(){
       if(this.$store.state.loaded == false){
        window.jQuery('#modal-hotsite').modal('show');
        this.$store.commit('alterarLoaded',true);
      }
    },
    buscaPorIngredientes(){
      this.buscaPorNome = false;
      this.buscarReceitas();
    },
    buscarPorNome(){
      this.buscaPorNome = true;
      this.buscarReceitas();
    },
    enviarSolicitacao(){
      this.enviarPalavra(this.solicitacao);
      this.solicitacao = '';
      alert('Registramos sua solicitação e a partir de agora priorizaremos a busca e inserção de receitas com esse ingrediente.');
    },
    enviarPalavra(palavra) {
        let formData = new FormData();
        formData.append('nome',palavra);
        fetch(servidor+'endpoint/palavras',{method: "POST",body: formData})
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          });
  },
    limparFiltos(){
      this.$store.commit('alterar',[]);
      this.ingredientes = [];
      this.dieta = '';
      this.categoria = '';
      this.dificuldade = '';
      this.custo = '';
      this.tempo = 0;
      this.$refs.nomeReceita.limpar();
    },
     fecharBoxIngrediente(){
      this.showMessageAutocomplete = false;
    },
    paginar(){
       this.showLoading = true;
       this.semResultados = false;
      fetch(servidor+'buscaTarimba/receitas',{method:"POST", body: this.query})
          .then((response) => response.json())
          .then((data) => {
            if(data == null || data.length == 0){ this.semResultados = true;}
            data.forEach(element => {
                this.receitas.push(element);
            });
          
            this.showLoading = false;
          });
    },
      checar(){
      if(this.queryIngredientes.length > 0){
        if(this.resultados.indexOf(this.queryIngredientes) == -1){
          this.showMessageAutocomplete = true;
          //this.enviarPalavra(this.queryIngredientes);
        }else{
          this.onSubmit(this.queryIngredientes);
        }
      }
    },
     search() {
       if(this.queryIngredientes.length > 1){
         this.showLoadingIngrediente = true;
        let formData = new FormData();
        formData.append('query',this.queryIngredientes);
        fetch(servidor+'autocomplete/ingredientesComReceita',{method: "POST",body: formData})
          .then((response) => response.json())
          .then((data) => {
            this.resultados = data;
            this.showLoadingIngrediente = false;
          })
          .catch(() =>  this.showLoadingIngrediente = false)
       }
    },

    onSubmit(result) {
     this.showLoadingIngrediente = false;
     this.ingredientes.push(result);
     this.queryIngredientes = "";
     this.$refs.typeahead.inputValue = "";
     this.showMessageAutocomplete = false;
     this.$store.commit('alterar',this.ingredientes);
     
    },
    removerIngrediente(index){
      this.ingredientes.splice(index,1);
      this.$store.commit('alterar',this.ingredientes);
    },

    getDietas() {
      return new Promise((resolve) => {
        fetch(servidor+'autocomplete/dietas')
          .then((response) => response.json())
          .then((data) => {
            resolve(data)
          })
      });
    },
    buscarReceitas(){
      this.semResultados = false;
      this.receitas = [];
      this.showLoading = true;
      fetch(servidor+'buscaTarimba/receitas',{method:"POST", body: this.query})
          .then((response) => response.json())
          .then((data) => {
             if(data == null || data.length == 0){ this.semResultados = true;}
            this.receitas = data;
            this.showLoading = false;
            //console.log(data)
          });
    }
  },
  computed:{
    query: function(){
      let filtro = new FormData();
      if(this.buscaPorNome == false && this.buscaReceita == ''){
        filtro.append('ingredientes',this.ingredientes);
        filtro.append('nome','');
      }
      if(this.buscaPorNome == false && this.buscaReceita != ''){
        filtro.append('ingredientes',this.ingredientes);
        filtro.append('nome','');
      }
      if(this.buscaPorNome == true){
         filtro.append('ingredientes',[]);
         filtro.append('nome',this.buscaReceita);
      }
      
      
      filtro.append('categoria',this.categoria);
      filtro.append('custo',this.custo);
      filtro.append('dificuldade',this.dificuldade);
      filtro.append('tempo',this.tempo);
      filtro.append('dieta',this.dieta);
      filtro.append('qtd',this.ingredientes.length);
      filtro.append('paginacao',this.receitas.length);
      return filtro;
    }
  },
  
  mounted(){
      this.ingredientes = this.$store.state.ingredientes;
      this.buscarReceitas();
      this.getDietas().then((response) => this.dietas = response);
     
      //let contexto = this;
      /*window.jQuery('.campo-busca').keyup(function(e) {
        if(e.key === "Enter"){ contexto.checar();}
      });*/

       window.jQuery('.campo-busca').prop('maxLength', 5);
       //this.abrirModalHotsite();
      this.$scrollTo("#topo");
  }
}
</script>